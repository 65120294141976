<template>
  <div class="outDiv">
    <div class="stat">
      <div class="date-side">
        <div class="date-title">选择日期</div>
        <div class="current-date" v-show="!year || !month">未选择年月</div>
        <div class="current-date" v-show="year && month">
          {{ year }}年-{{ month }}月
        </div>
        <div class="years">
          <div class="tips">请选择年份</div>
          <div class="year-tab">
            <i
              :class="[
                'my-icon',
                'el-icon-arrow-left',
                yearList[0] <= 2012 ? 'forbid' : '',
              ]"
              @click="beforeYear"
            ></i>
            <span>{{ yearList[0] }} — {{ yearList[yearList.length - 1] }}</span>
            <i class="my-icon el-icon-arrow-right" @click="afterTear"></i>
          </div>
          <div class="month-list">
            <div
              :class="['month-items', yearIndex === item ? 'active' : '']"
              v-for="(item, index) in yearList"
              :key="index"
              @click="changeYear(item)"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="month">
          <div class="tips">请选择月份</div>
          <div class="month-list">
            <div
              :class="['month-items', monthIndex === index ? 'active' : '']"
              v-for="(item, index) in monthList"
              :key="index"
              @click="changeMonth(item, index)"
            >
              {{ item }}月
            </div>
          </div>
        </div>
      </div>
      <div class="china-charts">
        <div class="china-charts-title">
          <span class="span">溯源扫码统计</span>
          <div class="count" v-for="(item, index) in countList" :key="index">
            <!-- {{ item }} -->
            <VueCountUp
              class="count-up"
              :start-value="0"
              :end-value="item"
              :duration="2"
              :decimals="0"
              :delay="1"
            />
          </div>
          <span class="span">次</span>
        </div>
        <div id="container"></div>
      </div>
      <div class="product-side">
        <div class="date-title">选择溯源产品</div>
        <div class="product-list" v-blockLoadMore="loadMoreProductData">
          <div
            :class="['product-items', productIndex === index ? 'active' : '']"
            v-for="(item, index) in tranceProductList"
            :key="item.id"
            @click="changeProduct(item.id, index)"
          >
            <img
              class="product-img"
              :src="
                item.photo
                  ? uploadImgs + item.photo
                  : require('@/assets/image/crops/item1.jpg')
              "
              alt=""
            />
            <span class="product-title">
              {{ item.title }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsonData from "@/assets/json/china.json";
import VueCountUp from "vue-countupjs";
import {
  getTranceProductListApi,
  getTranceStatisticApi,
} from "@/request/api.js";
import { mapState } from "vuex";

export default {
  components: {
    VueCountUp,
  },
  data() {
    return {
      year: "",
      month: "",
      productId: undefined,
      search_date: undefined,
      monthIndex: undefined,
      yearIndex: undefined,
      productIndex: undefined,
      monthList: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      yearList: [],
      tranceProductList: [],
      tranceProductCount: 0,
      tranceProductPn: 1,
      countList: [],
      dataList: [],
    };
  },
  watch: {
    isDate: {
      handler({ year, month }) {
        if (!year || !month) return;
        this.search_date = `${year}-${month}`;
        this.dataList = [];
        this.getTranceStatisticApi(this.search_date, this.productId);
      },
    },
    deep: true,
  },
  computed: {
    ...mapState({
      uploadImgs: (state) => state.app.uploadImgs,
      farmID: (state) => state.user.farmID,
    }),
    isDate() {
      let { year, month } = this;
      return { year, month };
    },
  },
  created() {
    this.initYear();
    this.getTranceStatisticApi();
    this.getTranceProductListApi();
  },
  methods: {
    initYear() {
      let nowYear = new Date().getFullYear();
      for (let index = 0; index < 12; index++) {
        this.yearList.unshift(nowYear--);
      }
    },
    changeProduct(id, index) {
      this.productIndex = index;
      this.productId = id;
      this.dataList = [];

      this.getTranceStatisticApi(this.search_date, id);
    },
    changeYear(year) {
      this.year = year;
      this.yearIndex = year;
    },
    changeMonth(month, index) {
      this.month = month;
      this.monthIndex = index;
    },
    loadMoreProductData() {
      if (
        this.tranceProductList.length &&
        this.tranceProductList.length == this.tranceProductCount
      )
        return;
      this.tranceProductPn++;
      this.getTranceProductListApi(this.tranceProductPn);
    },
    async getTranceStatisticApi(search_date = undefined, pid = undefined) {
      const { code, results } = await getTranceStatisticApi({
        manor_id: this.farmID,
        search_date,
        pid,
      });
      if (code != 0) return;
      this.countList = ("" + results.trance_count).split("");

      results.data.forEach((item) => {
        let obj = {
          name: "",
          value: [],
        };
        obj.name = item.name;
        obj.value[0] = +item.position.split(",")[0];
        obj.value[1] = +item.position.split(",")[1];
        obj.value[2] = item.value;
        this.dataList.push(obj);
      });
      this.initChinaEChart();
    },
    async getTranceProductListApi() {
      const { code, results } = await getTranceProductListApi({
        ps: 20,
        pn: 1,
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.tranceProductList = [...this.tranceProductList, ...results.data];
      this.tranceProductPn = results.pn;
      this.tranceProductCount = results.count;
    },
    initChinaEChart() {
      let max = 6000,
        min = 10,
        maxSize4Pin = 100,
        minSize4Pin = 20;
      let myChart = this.$echarts.init(document.getElementById("container"));
      this.$echarts.registerMap("china", jsonData);
      let option = {
        backgroundColor: "#0e2138",
        tooltip: {
          show: true,
          padding: [5],
          backgroundColor: "#0E2138",
          borderWidth: 0,
          textStyle: {
            color: "#fff",
          },
          formatter: function (params) {
            if (params.value.length > 1) {
              return (
                "&nbsp;&nbsp;" +
                params.name +
                "&nbsp;&nbsp;&nbsp;" +
                params.value[2] +
                "&nbsp;次&nbsp;&nbsp;"
              );
            } else {
              return (
                "&nbsp;&nbsp;" +
                params.name +
                "&nbsp;&nbsp;&nbsp;" +
                0 +
                "&nbsp;次&nbsp;&nbsp;"
              );
            }
          },
        },

        geo: {
          map: "china",
          type: "map",
          zoom: 1.15, //缩放比例
          show: true,
          roam: false, //禁止滚动等
          label: {
            emphasis: {
              show: false,
            },
          },
          layoutSize: "100%",
          itemStyle: {
            normal: {
              borderColor: this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "#00F6FF",
                  },
                  {
                    offset: 1,
                    color: "#53D9FF",
                  },
                ],
                false
              ),

              borderWidth: 3,
              shadowColor: "rgba(10,76,139,1)",
              shadowOffsetY: 0,
              shadowBlur: 60,
            },
          },
        },

        series: [
          {
            type: "map",
            map: "china",
            aspectScale: 0.75,
            zoom: 1.15,
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", 
                    },
                    {
                      offset: 1,
                      color: "#061E3D", 
                    },
                  ],
                },
                borderColor: "#215495",
                borderWidth: 1,
              },
              emphasis: {
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", 
                    },
                    {
                      offset: 1,
                      color: "#061E3D", 
                    },
                  ],
                },
              },
            },
            data: [],
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              brushType: "stroke",
            },
            showEffectOn: "render",
            itemStyle: {
              normal: {
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(5,80,151,0.2)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(5,80,151,0.8)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,108,255,0.7)",
                    },
                  ],
                  global: false, 
                },
              },
            },
            label: {
              normal: {
                show: true,
                color: "#fff",
                fontWeight: "bold",
                position: "inside",
                formatter: function (para) {
                  return "{cnNum|" + para.data.value[2] + "}";
                },
                rich: {
                  cnNum: {
                    fontSize: 13,
                    color: "#D4EEFF",
                  },
                },
              },
            },
            symbol: "circle",
            symbolSize: function (val) {
              if (val[2] === 0) {
                return 0;
              }
              let a = (maxSize4Pin - minSize4Pin) / (max - min);
              let b = maxSize4Pin - a * max;
              return a * val[2] + b * 1.2;
            },
            data: this.dataList,
            zlevel: 1,
          },
        ],
      };
      myChart.setOption(option);
    },
    beforeYear() {
      if (this.yearList[0] <= 2012) return;
      this.yearList = this.yearList.map((item) => {
        return (item = item - 12);
      });
    },
    afterTear() {
      this.yearList = this.yearList.map((item) => {
        return (item = item + 12);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.outDiv {
  .stat {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #071a2c;
    .date-side {
      width: 413px;
      height: 80vh;

      background: #142d4c;
      padding: 24px 55px;
      .date-title {
        width: 100%;
        line-height: 22px;
        height: 22px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        padding-left: 11px;
        border-left: 4px solid #3e90e5;
        border-radius: 2px;
        margin-bottom: 28px;
      }
      .current-date {
        width: 100%;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #3e90e5;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
        margin-bottom: 31px;
      }

      .years,
      .month {
        .tips {
          font-size: 14px;
          color: #fff;
          margin-bottom: 13px;
          &::before {
            content: "*";
            color: #ff0000;
            margin-right: 2px;
          }
        }
        .month-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .month-items {
            width: 90px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            background: #173355;
            border: 1px solid #1b3e68;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            margin: 0 16px 24px 0;
            cursor: pointer;
            &.active {
              background: #3e90e5;
            }
            &:nth-of-type(3n) {
              margin-right: 0;
            }
          }
        }
      }
      .years {
        margin-bottom: 30px;
        .year-tab {
          width: 100%;
          height: 36px;
          background: #224d7f;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-size: 14px;
          color: #fff;
          margin-bottom: 30px;
          .my-icon {
            color: #fff;
            font-size: 16px;
            cursor: pointer;
            &:hover {
              color: #3e90e5;
            }
            &.forbid:hover {
              color: #fff;
              cursor: no-drop;
            }
          }
        }
      }
    }
    .china-charts {
      width: 1070px;
      height: 80vh;
      background: #0e2138;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .china-charts-title {
        width: 100%;
        display: flex;
        align-items: flex-end;
        padding: 40px 60px;
        .span {
          font-size: 22px;
          color: #fff;
          margin: 0 9px;
        }
        .count {
          width: 30px;
          height: 43px;
          line-height: 43px;
          text-align: center;
          background: #3e90e5;
          margin: 0 3px 0 0;
          font-size: 36px;
          font-weight: bold;
          color: #fff;
          &:last-of-type {
            margin: 0;
          }
        }
      }
      #container {
        width: 1070px;
        height: calc(100% - 40px);
      }
    }
    .product-side {
      width: 418px;
      height: 80vh;

      background: #142d4c;
      padding: 24px 10px 24px 40px;
      .date-title {
        width: 100%;
        line-height: 22px;
        height: 22px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        padding-left: 11px;
        border-left: 4px solid #3e90e5;
        border-radius: 2px;
        margin-bottom: 28px;
      }
      .product-list {
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        overflow-y: auto;
        padding: 0 0 20px;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }

        .product-items {
          width: 102px;
          height: 103px;
          background: #173355;
          border: 1px solid #1b3e68;
          border-radius: 4px;
          margin: 0 12px 16px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &.active {
            background: #3e90e5;
          }
          &:nth-of-type(3n) {
            margin-right: 0;
          }
          .product-img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            background-color: #fff;
            margin-bottom: 14px;
          }
          .product-title {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
